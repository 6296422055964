import { Theme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';

export default makeStyles<Theme>((theme) =>
  createStyles({
    brandCategoriesWrapper: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'flex-start',
      maxWidth: '100%',
      overflow: 'hidden',
      width: '100%',

      '& > a': {
        marginBottom: '16px',
        width: 'calc(100% / 6)',
      },
    },
    container: {
      [theme.breakpoints.down('md')]: {
        height: 48,
      },
      maxWidth: 1064,
      width: '100%',
    },
    containerBrands: {
      margin: '0 -16px',
      maxWidth: 1064,
      width: 'calc(100% + 32px)',
    },
    mobileBrandCategoriesCarousel: {
      '&$mobileBrandCategoriesCarouselMenu': {
        paddingLeft: 16,
        paddingRight: 16,
      },
      display: 'flex',
      height: 56,
      justifyContent: 'space-between',
      margin: '0 -4px 8px -4px',
      overflow: 'auto',
      paddingLeft: 10,
      paddingRight: 10,
      width: '100%',
    },
    mobileBrandCategoriesCarouselMenu: {},
    mobileBrandCategoriesCarouselWrapper: {
      display: 'flex',
      height: 48,
      justifyContent: 'space-between',
      overflow: 'hidden',
      width: '100%',
    },
  }),
);
