import classNames from 'classnames';
import React from 'react';

import Hidden from '@components/common/Hidden';
import { IHomePageComponentDataObject } from '@interfaces/home';
import BrandCategory from './BrandCategory';

import BrandCategoriesWrapperStyles from './BrandCategoriesWrapper.styles';

interface IBrandCategoriesWrapperProps {
  component: IHomePageComponentDataObject;
  className?: string;
}

const BrandCategoriesWrapper: React.FunctionComponent<IBrandCategoriesWrapperProps> = ({
  component,
  className
}) => {
  const classes = BrandCategoriesWrapperStyles();

  return (
    <>
      <Hidden mdDown={true}>
      <div className={classNames(classes.brandCategoriesWrapper, classes.container, className)}>
        {component.brands?.map((brand, index) => {
          return <BrandCategory brand={brand} key={`brand-${index}`} />;
        })}
      </div>
      </Hidden>
      <Hidden mdUp={true}>
      <div className={classNames(classes.mobileBrandCategoriesCarouselWrapper, classes.container, className)}>
        <div className={classes.mobileBrandCategoriesCarousel}>
          {component.brands?.map((brand, index) => {
            return <BrandCategory brand={brand} key={`brand-${index}`} />;
          })}
        </div>
      </div>
      </Hidden>
    </>
  );
};

export default BrandCategoriesWrapper;
