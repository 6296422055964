import React, { useContext } from 'react';

import { BrandCategoryElement } from '@components/pages/home/brand-categories/BrandCategoryElement';
import { EGenderType } from '@constants/gender';
import { getMediaUrl } from '@constants/urls';
import { GenderContext } from '@context/gender';
import { IBrandItem } from '@interfaces/home';
import { mapGenderIdToType } from '@utils/gender';

interface IBrandCategoryProps {
  brand: IBrandItem;
}

const BrandCategory: React.FunctionComponent<IBrandCategoryProps> = ({ brand = null }) => {
  const { sexId } = useContext(GenderContext);
  const genderType = mapGenderIdToType(sexId);
  const url = brand && (genderType === EGenderType.man ? brand.menLink || brand.womenLink : brand.womenLink);
  return brand && url ? (
    <BrandCategoryElement url={url} mediaUrl={getMediaUrl(brand.media)} name={'brand_image'} />
  ) : null;
};

export default BrandCategory;
