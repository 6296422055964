import Image from 'next/image';
import React from 'react';

import Link from '@components/common/Link';
import { PageTypes } from '@constants/pageTypes';
import { useScreenSize } from '@hooks/useScreenSize';

import BrandCategoryStyles from './BrandCategory.styles';

interface Props {
  url: string;
  mediaUrl: string;
  name: string;
}

export const BrandCategoryElement: React.FC<Props> = ({ url, mediaUrl, name }) => {
  const classes = BrandCategoryStyles();
  const { isMobile } = useScreenSize();
  return (
    <Link className={classes.brandCategory} href={url} pageType={PageTypes.productsList}>
      {mediaUrl ? <Image
        className={classes.brandCategoryImage}
        alt={name}
        height={isMobile ? 40 : 64}
        width={isMobile ? 92 : 146}
        src={mediaUrl}
      //  src={getMediaUrl(mediaUrl)}
      /> : <span className={classes.text}>{name}</span>}
    </Link>
  );
};
