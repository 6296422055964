import { Theme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import { ColorsPalette } from '@themes/colors';
import { TypographyCollection } from '@themes/font';

export default makeStyles<Theme>((theme) =>
  createStyles({
    brandCategory: {
      ...{
        alignItems: 'center',
        boxSizing: 'border-box',
        color: ColorsPalette.primary,
        display: 'flex',
        height: 64,
        justifyContent: 'center',
      },
      ...TypographyCollection.HLinkBoldS,
      [theme.breakpoints.down('md')]: {
        height: 40,
        margin: '0 4px',
        width: 92,
      },
    },
    brandCategoryImage: {
      [theme.breakpoints.down('lg')]: {
        maxHeight: '62px',
      },
      [theme.breakpoints.down('md')]: {
        maxHeight: '40px',
      },
      display: 'block',
      height: '64px',
      width: 'auto',
    },
    text: {
      whiteSpace: 'nowrap',
    },
  })
);
